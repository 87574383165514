<template>
  <v-row dense>
    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.name" :label="$t('shared.name')" :rules="'required|max:100'" dir="auto" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.tel" :label="$t('shared.phone')" dir="auto" :rules="'max:20'" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <mobile-input v-model="value.fax" dir="ltr" :label="$t('organizations.fax')" :rules="'numeric'" />
    </v-col>

    <v-col cols="12" sm="6" md="4" class="d-flex align-center">
      <v-switch v-model="value.active" :label="$t('shared.active')" inset />
    </v-col>

    <v-col cols="12">
      <c-input textarea v-model="value.address" rows="3" :label="$t('shared.address')" :rules="'max:500'" />
    </v-col>

    <v-col cols="12">
      <GeopointEditor v-model="coordinate" :label="$t('shared.coordinate')" height="250" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <uploader v-model="value.logo" :label="$t('shared.logo')" />
    </v-col>
  </v-row>
</template>

<script>
import {GeopointEditor} from '@/components/shared'
export default {
  name: 'BaseForm',
  components: {GeopointEditor},
  props: {
    value: {type: Object, default: () => ({})}
  },
  computed: {
    coordinate: {
      get() {
        return {
          lat: this.value.latitude,
          lng: this.value.longitude
        }
      },
      set(value) {
        if (!value) {
          this.value.latitude = null
          this.value.longitude = null
        } else {
          this.value.latitude = value.lat
          this.value.longitude = value.lng
        }
      }
    }
  }
}
</script>
